<template>
  <v-row class="app-preview">
    <v-col
      cols="12"
      md="9"
    >
      <template v-for="(state, index) in ['Original', 'Copy']">
        <v-card
          v-if="quotation"
          :key="index"
          class="printable--theme mb-3"
          :class="{ 'cut-line': state === 'Original' }"
        >
          <div class="text-right pr-5 pt-3">
            <p class="mb-0 printable-text font-weight-medium">
              {{ state }}
            </p>
          </div>
          <div class="pb-2 px-5">
            <!-- Letterhead -->
            <Letterhead v-if="state === 'Original'" />

            <v-row class="pt-2">
              <!-- Date -->
              <v-col
                cols="8"
                class="d-flex align-center"
              >
                <p class="me-2 mb-0 printable-text font-weight-medium">
                  Date Issued: {{ quotation.created_at }}
                </p>
              </v-col>

              <!-- Quotation number -->
              <v-col
                cols="4"
                class="d-flex align-center"
              >
                <p class="me-2 mb-0 printable-text font-weight-medium">
                  Official Quotation No:
                  <span class="error--text">{{ quotation.quotation_number }}</span>
                </p>
              </v-col>
            </v-row>
          </div>

          <v-divider class="printable-divider"></v-divider>

          <div class="py-2 px-5">
            <v-row>
              <!-- Customer Details -->
              <v-col cols="8">
                <p class="font-weight-semibold payment-details-header mb-1">
                  Quoted To:
                </p>
                <v-row>
                  <v-col>
                    <p class="mb-0">
                      {{ quotation.company_name }}
                    </p>
                    <template v-if="quotation.insurance_product.name === 'Motor insurance'">
                      <p class="mb-0">
                        Car registration: {{ quotation.car_registration_number }}
                      </p>
                    </template>
                    <p class="mb-0">
                      Insurance: {{ quotation.insurance.name }}
                    </p>
                    <template v-if="quotation.insurance_product.name === 'Motor insurance' && quotation.bluecard_finance">
                      <p class="mb-0">
                        Finance: {{ quotation.bluecard_finance.name }}
                        <span v-if="quotation.bluecard_finance.name === 'Others'">({{ quotation.bluecard_finance_remarks }})</span>
                      </p>
                    </template>
                  </v-col>
                  <v-col>
                    <p class="mb-0">
                      Sum insured: {{ quotation.sum_insured }}
                    </p>
                    <p class="mb-0">
                      No claim discount: {{ quotation.no_claim_discount }}%
                    </p>
                    <p class="mb-0">
                      Hibah/surplus: {{ quotation.surplus_hibah }}
                    </p>
                    <p class="mb-0">
                      Period: {{ quotation.period }} days
                    </p>
                    <template v-if="quotation.general_remarks !== null">
                      <p class="mb-0">
                        Remarks: {{ quotation.general_remarks }}
                      </p>
                    </template>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                v-if="state === 'Original'"
                align="center"
                class="mt-1"
              >
                <qrcode-vue
                  :value="quotation.record_preview"
                  level="L"
                />
                <p>Scan for authenticity</p>
              </v-col>
            </v-row>
          </div>

          <v-divider class="printable-divider"></v-divider>

          <Details :record="quotation" />

          <v-divider class="printable-divider"></v-divider>

          <Signatures />

          <template v-if="state === 'Original' && $vuetify.breakpoint.smAndUp">
            <v-divider class="printable-divider"></v-divider>
            <Branches />
          </template>
        </v-card>
      </template>
      <v-card v-if="!quotation">
        <v-skeleton-loader
          height="100%"
          type="image, image, image, image, image, image"
        />
      </v-card>
    </v-col>

    <!-- Right Column: Quotation Action -->
    <v-col
      cols="12"
      md="3"
    >
      <v-card>
        <v-card-text>
          <v-btn
            :disabled="quotation && quotation.deleted_at != null"
            color="primary"
            block
            @click="printQuotation"
          >
            <v-icon
              class="me-2"
              left
            >
              {{ icons.mdiPrinter }}
            </v-icon>
            <span>{{ t('Print') }}</span>
          </v-btn>
        </v-card-text>

        <v-card-text
          v-if="quotation && quotation.deleted_at"
          class="text-center"
        >
          <p class="error--text mb-0">
            {{ t('This quotation has been voided.') }}
          </p>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {
  ref,
  onUnmounted,
  onMounted,
  inject,
} from '@vue/composition-api'
import router from '@/router'
import { mdiPrinter } from '@mdi/js'
import QrcodeVue from 'qrcode.vue'
import {
  Letterhead,
  Details,
  Signatures,
  Branches,
} from '@/components/apps/previews'
import quotationStoreModule from './quotationStoreModule'

export default {
  components: {
    Letterhead,
    Details,
    Signatures,
    Branches,
    QrcodeVue,
  },
  setup() {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const t = inject('t')
    const QUOTATION_APP_STORE_MODULE_NAME = 'app-quotation'

    if (!store.hasModule(QUOTATION_APP_STORE_MODULE_NAME)) {
      store.registerModule(QUOTATION_APP_STORE_MODULE_NAME, quotationStoreModule)
    }

    // Properties
    const quotation = ref(null)

    // Methods
    const fetchQuotation = () => {
      store
        .dispatch('app-quotation/fetchQuotation', { id: router.currentRoute.params.id })
        .then(response => {
          quotation.value = response.data.data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching quotation. Please refresh!')
        })
    }
    const printQuotation = () => window.print()

    window.onbeforeprint = () => {
      document.title = `quotation-${quotation.value.quotation_number}`
    }

    window.onafterprint = () => {
      document.title = 'Niagamas - A Clericle product'
    }

    // Mounted
    onMounted(() => fetchQuotation())

    // Unmounted
    onUnmounted(() => {
      if (store.hasModule(QUOTATION_APP_STORE_MODULE_NAME)) store.unregisterModule(QUOTATION_APP_STORE_MODULE_NAME)
    })

    return {
      t,
      quotation,
      printQuotation,
      icons: {
        mdiPrinter,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/style.scss';

@page {
  size: A4;
  margin: 0mm;
}

.app-preview {
  .printable--theme {
    p {
      font-size: 9px;
    }
  }
}

@media print {
  body {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
  }

  .v-application {
    background: none !important;
  }

  .app-navigation-menu,
  .v-app-bar,
  .v-footer,
  .app-preview.row .col-12:last-of-type {
    display: none !important;
  }

  .app-preview {
    .printable--theme {
      border-radius: 0px;
    }

    .cut-line {
      border-bottom: 1px red dashed;
    }
  }

  .app-preview.row .col-12:first-child {
    max-width: 100% !important;
    flex: 1 !important;
  }

  .v-main,
  .app-content-container {
    padding: 0px !important;
  }

  .v-card {
    box-shadow: none !important;
  }

  .app-preview {
    .quotation-header,
    .payment-details,
    .quotation-total {
      &.d-flex {
        flex-direction: row !important;
      }
    }
  }
}
</style>
